<template>
  <div class="Site IndexBox">
    <div style="padding-left: 15px;padding-top: 10px;background-color: #13171A">
      <img :src="`./static/images/logo.png`" width="100"></img>
    </div>
    <div class="ScrollBox Home">
      <div>
          <van-swipe :autoplay="3000"
                     indicator-color="#888"
                     class="homeswipe">
            <van-swipe-item v-for="(item, index) in bannerList" :style="'background:url('+item+');background-size: 100% 100%'" :key="index">
<!--              <img ref="img" :src="item"  width="100%" />-->
            </van-swipe-item>
          </van-swipe>
      </div>
      <div class="header_link">
        <van-notice-bar color="#aaaaaa" background="none"
                        left-icon="volume-o"
                        :text="noticeBarContent"
        />
      </div>
      <div class="header">
        <van-grid
                class="Menu"
                direction="vertical"
                :column-num="4"
                :border="false"
                gutter="4"
        >
          <van-grid-item @click="gotoWait()">
            <div>
              <img :src="`./static/icon/nav01.png`" style="height: 3.0rem" />
            </div>
            <div>{{ $t('home3[0]') }}</div>
          </van-grid-item>
          <van-grid-item @click="gotoWait()">
            <div>
              <img :src="`./static/icon/nav02.png`"  style="height: 3.0rem" />
            </div>
            <div>{{ $t('home3[1]') }}</div>
          </van-grid-item>
          <van-grid-item @click="gotoWait()">
            <div>
              <img :src="`./static/icon/nav03.png`" style="height: 3.0rem" />
            </div>
            <div>{{ $t('home3[2]') }}</div>
          </van-grid-item>
          <van-grid-item @click="gotoWait()">
            <div class="four">
              <img :src="`./static/icon/nav04.png`" style="height: 3.0rem"
              />
            </div>
            <div>{{ $t('home3[3]') }}</div>
          </van-grid-item>
          <van-grid-item to="/service">
            <div class="four">
              <img :src="`./static/icon/nav05.png`" style="height: 3.0rem"
              />
            </div>
            <div>{{ $t('home.menu[1]') }}</div>
          </van-grid-item>
          <van-grid-item :to="`/user/invite/${UserInfo.idcode}/${UserInfo.idcode}`">
            <div class="four">
              <img :src="`./static/icon/nav06.png`" style="height: 3.0rem"
              />
            </div>
            <div>{{ $t('home.menu[2]') }}</div>
          </van-grid-item>
          <van-grid-item to="/user/teamReport">
            <div class="four">
              <img :src="`./static/icon/nav07.png`" style="height: 3.0rem"
              />
            </div>
            <div>{{ $t('user.menu[1]') }}</div>
          </van-grid-item>
          <van-grid-item to="/serviceCenter">
            <div class="four">
              <img :src="`./static/icon/nav08.png`" style="height: 3.0rem"/>
            </div>
            <div>{{ $t('home.menu[3]') }}</div>
          </van-grid-item>
        </van-grid>
      </div>
      <div>
        <van-grid class="Menu2"
                :column-num="2"
                :border="false"
                gutter="4">
          <van-grid-item to="/buy" class="Menu2item">
            <div class="Menu2item1">
              <div style="color: #fff">{{ $t('home4[0]') }}</div>
              <div>{{ $t('home4[1]') }}</div>
              <div style="display: flex;margin-top: 10px">
                <div style="width: 85%">
                  <img :src="`./static/images/homecard0.png`" style="height: 3.2rem"/>
                </div>
                <div>
                <img :src="`./static/icon/arrow1.png`" style="height: 1.5rem;margin-top: 15px"
                />
                </div>
              </div>
          </div>
          </van-grid-item>
          <van-grid-item to="/sell" class="Menu2item">
            <div class="Menu2item1">
              <div style="color: #fff">{{ $t('home4[2]') }}</div>
              <div>{{ $t('home4[3]') }}</div>
              <div style="display: flex;margin-top: 10px">
                <div style="width: 85%">
                  <img :src="`./static/images/homecard1.png`" style="height: 3.2rem"/>
                </div>
                <div>
                  <img :src="`./static/icon/arrow1.png`" style="height: 1.5rem;margin-top: 15px"
                  />
                </div>
              </div>
            </div>
          </van-grid-item>
          <van-grid-item class="Menu2item">
            <div class="Menu2item1">
              <div>{{ $t('home4[4]') }}</div>
              <div style="display: flex;margin-top: 10px">
                <div style="width: 75%;color:#F0B80D;margin-left: 5px">
                  {{onlineCounts}}
                </div>
                <div>
                  <img :src="`./static/images/homecard2.png`" style="height: 3.0rem"/>
                </div>
              </div>
            </div>
          </van-grid-item>
          <van-grid-item class="Menu2item">
            <div class="Menu2item1">
              <div>{{ $t('home4[5]') }}</div>
              <div style="display: flex;margin-top: 10px">
                <div style="width: 75%;color:#F0B80D;margin-left: 5px">
                  {{totalAmount}}
                </div>
                <div>
                  <img :src="`./static/images/homecard3.png`" style="height: 3.0rem"/>
                </div>
              </div>
            </div>
          </van-grid-item>
        </van-grid>
      </div>
      <div class="tool">
        <van-grid column-num="3" :border="false" style="color:#fff">
          <van-grid-item v-for="(item, index) in currency0">
            <div style="font-size: 13px;font-weight: bold"> {{item.name}}/<span style="color: #9b9b9b">{{InitData.usdtinfo.sign}}</span></div>
            <div style="color: #F46926;font-size: 14px;font-weight: bold"> {{parseFloat(item.price).toFixed(2)}}</div>
            <div v-if="item.rate>0" style="color: #30ac69;font-size: 13px;font-weight: bold">
              {{parseFloat(item.rate).toFixed(2)}}%
            </div>
            <div v-else style="color: red;font-size: 13px;font-weight: bold">
              {{parseFloat(item.rate).toFixed(2)}}%
            </div>
          </van-grid-item>
        </van-grid>
      </div>
<!--      <canvas id="chart0" class="charts"></canvas>-->
<!--      <canvas id="chart1" class="charts"></canvas>-->
      <div class="homelist">
        <div v-for="(item, index) in currency2" class="coinitem">
          <table style="font-size:13px;width: 100%;padding:8px 5px 7px 0px">
            <tr>
              <td style="width: 6%">
                <img :src="`./static/icon/`+item.star.toLowerCase()+`.png`" style="height: 1.2rem" />
              </td>
              <td style="width: 8%">
                <img :src="`./static/currency/`+item.name.toLowerCase()+`.png`" style="height: 1.5rem" />
              </td>
              <td style="font-size: 16px;width: 15%;">
                {{item.name}}
              </td>
              <td style="font-size: 16px;text-align: left;width: 30%">
                <div style="line-height: 20px">$ {{$Util.formatNum(parseFloat(item.price0).toFixed(2))}}
                </div>
                <div style="font-size: 11px" v-if="item.volume">Vol : {{$Util.formatNum(parseFloat(item.volume/1000).toFixed(2))}}k
                </div>
              </td>
              <td style="width:100px">
                <div>
                  <canvas :id="'chart'+item.name" class="charts"></canvas>
                </div>
              </td>
              <td style="text-align: right;padding-right: 0px;font-size: 12px">
                <div v-if="item.rate>0" class="rate1">+{{parseFloat(item.rate).toFixed(2)}}%</div>
                <div v-else class="rate2">{{parseFloat(item.rate).toFixed(2)}}%</div>
              </td>
            </tr>
          </table>
        </div>
        <div style="height: 70px"></div>
      </div>
    </div>
    <Footer />
    <van-popup
            v-model="showWait"
            style="background: transparent; width: 80%; text-align: center"
    >
      <div class="popupBg" style="background-color: #191C23;height: 140px;padding-top: 50px;color:#fff;font-size: 20px">
        {{ $t('home3[4]') }}
      </div>
    </van-popup>
    <van-popup
            v-model="showNotice"
            style="background: transparent; width: 80%; text-align: center"
            @closed="$parent.isNotice = true"
    >
      <div class="popupBg">
        <dl
                class="ScrollBox NoticePopup"
                v-if="InitData.noticelist && InitData.noticelist.length"
                @click="$router.push(`/article/notice/${InitData.noticelist[0].id}`)"
        >
          <dt class="popup_title">
            <div style="margin: 0 auto">
              <span :class="{ letter_s: language1 }">{{
                $t("home.noticeTitle")
              }}</span
              ><span>NOTICE</span>
            </div>
          </dt>
          <dd v-html="InitData.noticelist[0].content" style="color: #000"></dd>
        </dl>
      </div>
      <a class="close" href="javascript:;" @click="showNotice = false">
        <img src="../../public/static/images/pupup_false.png" />
      </a>
    </van-popup>
  </div>
</template>

<script>
  import Service from "@/common/Service";
import $ from "jquery";
import uCharts from "@/components/ucharts/u-charts.js"
var uChartsInstance = {};
// import {ref} from 'vue'
export default {
  name: "Home",
  inject: ["reloadHtml"],
  components: {},
  props: ["user_id"],
  data() {
    return {
      currentRate: 0,
      showWait:false,
      onlineCounts:parseInt(Math.random()*80000+200000),
      totalAmount:parseInt(Math.random()*8000000+10000000),
      showNotice: false,
      tabActive: 0, //任务大厅
      tabActive1: 0, //榜单
      currency0:[],
      currency2:[],
      counts:{},
      noticeBarContent:"",
      language1: localStorage.getItem("Language") == "cn",
      bannerList: "",
      moeny: "",
      timer: null, //定时器
      timer2: null, //定
      chartTimer:null,
      currHeader:'head_6.png',
    };
  },

  computed: {
    text() {
      // return this.currentRate.toFixed(0) + '%'
      return "loading";
    },
  },
  watch: {
    "InitData.noticelist"(data) {
      if (!this.$parent.isNotice && data.length) this.showNotice = true;
    },
  },
  created() {
    if (!localStorage["Token"]) {
      this.$router.push("/login");
      return;
    }
    this.timer = setInterval(() => {
      // console.log("get prices");
      this.onlineCounts = parseInt(Math.random()*80000+200000);
    }, 30000);

    this.timer2 = setInterval(() => {
      // console.log("get prices");
      this.totalAmount = parseInt(Math.random()*8000000+10000000)
    }, 60000*30);

    window.callReadFunction = this.callReadFunction;
    if (localStorage["Token"]) {
      // 查询绑定银行卡
      this.$Model.GetBankCardList();
    }
    var ret = this.$Util.readAppStorage();
    console.log("欢迎");
    this.$Model.GetBackData(data=>{
      this.bannerList = data.bannerList;
      this.counts = data.counts;
      for (let i=0;i<data.drawNotice.length;i++){
        this.noticeBarContent += data.drawNotice[i]['content']+"; "
      }
    });
    // alert("xxxxxxxxxxxxx:"+ret)
  },
  mounted() {
    if (this.UserInfo.header){
      this.currHeader = this.UserInfo.header;
    }
    //获取浏览器地址
    let getHref = window.location.href;

    this.getPrices();
    // console.log(this.$refs)
    // for(let i=0;i<)
  },
  activated() {},
  destroyed() {},
  beforeDestroy() {
    console.log("clear home timer");
    clearInterval(this.timer);
    clearInterval(this.timer2);
    clearInterval(this.chartTimer);
  },
  methods: {
    gotoWait(){
      this.showWait = true
    },
    callReadFunction(data) {
      localStorage["AppData"] = data;
      // this.$toast(data)
    },
    getPrices() {
      var that = this;
      that.setCoinData(that.InitData);
      setTimeout(function() {
        that.timer = setInterval(() => {
          // console.log("get prices");
          that.getCommonData2();
        }, 8000);
      }, 5000);
      // that.chartTimer = setInterval(() => {
      //   console.log("getCommonData222222");
      //   that.getCommonData2();
      // }, 60000*30);
    },
    getCommonData(){
      var that = this;
      that.time1 = new Date().getTime();
      this.$Model.GetCommonData(data=>{
        that.setCoinData(data)
      });
    },
    getCommonData2(){
      var that = this;
      that.time1 = new Date().getTime();
      this.$Model.GetCommonData(data=>{
        that.setCoinData2(data)
      });
    },
    setCoinData(data){
      var that = this;
      let time2 = new Date().getTime();
      console.log("time cost00: "+(time2-that.time1))
      let coins = data.currencylist;
      if (!coins){
        return
      }
      if (coins.length<3){
        this.$Dialog.Toast("no currency found");
        return;
      }
      let coins0 = [];
      for (var i=0;i<3;i++){
        coins0.push(coins[i]);
      }
      this.currency0 = coins0;
      coins.forEach(coin=>{
        var ii = "star1";
        var kk = Math.round(Math.random()*10);
        if (kk>5){
          ii = "star2"
        }
        coin['star'] = ii;
      })
      this.currency2 = coins;
    },
    setCoinData2(data){
      var that = this;
      let time2 = new Date().getTime();
      console.log("time cost00: "+(time2-that.time1))
      let coins = data.currencylist;
      if (!coins){
        return
      }
      if (coins.length<3){
        this.$Dialog.Toast("no currency found");
        return;
      }
      let coins0 = [];
      for (var i=0;i<3;i++){
        coins0.push(coins[i]);
      }
      this.currency0 = coins0;
      coins.forEach(coin=>{
        var ii = "star1";
        var kk = Math.round(Math.random()*10);
        if (kk>5){
          ii = "star2"
        }
        coin['star'] = ii;
      })
      this.currency2 = coins;
      console.log("curr222: "+JSON.stringify(coins))
      time2 = new Date().getTime();
      this.loadChartData(coins)
      console.log("time cost: "+(time2-that.time1))
    },
    goTask(index, state) {
      if (state == 3) {
        this.$toast.fail(this.$t("home.msg"));
      } else {
        let grade = this.UserInfo.vip_level;
        let taskType = this.InitData.taskclasslist.filter(
          (obj) => obj.state == 1
        )[index].group_id;
        /* this.$router.push(`/taskList/${taskType}/${grade}`); */
        this.$router.push("/GrabOrders");
      }
    },

    // 打招呼
    goChatPage(id) {
      this.$router.push(`/user/chatPage/${id}`);
    },
    toGrabtask() {
      console.log(1);
      this.$router.push("/user/Grabtask");
    },
    showCharts(id,data,color){
      const canvas = document.getElementById(id);
      const ctx = canvas.getContext("2d");
      canvas.width = 100;
      canvas.height = 50;
      canvas.width = canvas.offsetWidth;
      canvas.height = canvas.offsetHeight;
      var element = document.getElementById(id);
      element = new uCharts({
        color:[color],
        type: "line",
        context: ctx,
        width: canvas.width,
        height: canvas.height,
        categories: data.categories,
        series: data.series,
        dataLabel: false,
        dataPointShape:false,
        legend: {
          show:false,
          float:"left",
        },
        xAxis: {
          labelCount: 15,
          rotateLabel: true,
          disabled:true,
          axisLine:false,
          disableGrid: true,
          gridType:"dash"
        },
        yAxis: {
          dashLength: 2,
          disableGrid: true,
          disabled:true,
        },
        extra: {
          line: {
            type: "curve",
          }
        }
      });
      // canvas.onclick = function(e) {
      //   uChartsInstance[e.target.id].touchLegend(getH5Offset(e));
      //   uChartsInstance[e.target.id].showToolTip(getH5Offset(e));
      // };
      // canvas.onmousemove=function(e) {
      //   uChartsInstance[e.target.id].showToolTip(getH5Offset(e));
      // };
    },
    doShow2(){
      _MEIQIA('showPanel');
    },
    loadChartData(coinData) {
      var that = this;
      var rates = {};
      for (var i=0;i<coinData.length;i++){
        var d = coinData[i];
        rates[d.name] = d.rate;
      }
      this.$Model.GetKlineData(data=>{
        var klinedatas = data;
        for(var key in data){
          var ddd = klinedatas[key];
          var ele = document.getElementById("chart"+key);
          if (ele){
            var color = "green";
            var rr = rates[key];
            if (rr&&rr<0){
              color = "red";
            }
            if (key=='LTC'){
              // console.log("目标值: "+JSON.stringify(ddd))
            }
            // console.log(key+" kinle: "+JSON.stringify(ddd))
            let res = {
              categories: ["2016","2017","2018","2019","2020","2021","2019","2020","2021"],
              series: [
                {
                  name: "目标值",
                  data: ddd
                }
              ]
            };
            that.showCharts('chart'+key, res,color);
          }
        }


      });
    }
  },
};
</script>
<style scoped>
  .PageBox {
    background-color: #13171A;
    font-family: Alibaba;
  }

  .header_bg {
    top: 130px;
    z-index: 0;
  }

  .ScrollBox{
    background-color: #13171A;
  }
  .BroadBarItem {
    width: 100%;
  }


  .header_link {
    padding: 0 10px;
    margin-top: 5px;
    border-radius: 30px;
  }

  .header_link >>> .van-cell {
    border-radius: 30px;
    background-color: #8c60f1;
    padding: 7px 16px;
    margin-top: 10px;
  }

  .header_link >>> .van-cell__value span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: Alibaba;
    color: #fff;
    font-size: 13px;
  }

  .header >>> .Menu {
    justify-content: space-between;
    padding: 0px 5px !important;
    background-color: #191C23;
    color:red;
    width: 100%;
    max-width: 450px;
    font-size: 11px;
    border-radius: 10px;
    height: 180px;
    margin: 10px auto 0px;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }

  .header >>> .Menu2 {
    justify-content: space-between;
    width: 100%;
    max-width: 450px;
    font-size: 11px;
    border-radius: 10px;
    flex-wrap: wrap;
    text-align: left;
  }

  .swipe1 {
    /*background-url:"/upload/resource/2022072919385138533.jpeg";*/
    /*background: url("/upload/resource/2022072919385138533.jpeg");*/
    background-size: 100% 100%;
  }
  .Menu2 >>> .van-grid-item__content {
    /*background-color: #151d31;*/
    border-radius: 5px;
    color:#cccccc;
    background: transparent none repeat scroll 0% 0%;
  }

  .Menu2item {
    height: 110px;
  }

   .Menu2item1 {
     height: 105px;
     background-color: #191C23;
     width: 100%;
     text-align: left;
     line-height: 22px
   }

  .tool >>> .van-grid-item__content {
    padding: 10px !important;
    display: flex;
    background-color:#191C23;
  }

  .homeswipe {
    border-radius: 10px;
    width: 96%;
    margin-left: 6px;
    height: 10rem;
    z-index: 50;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .homelist {
    background-color: #13171A;
  }
  .header >>> .Menu .van-grid-item__content {
    padding: 0px;
    display: flex;
  }

  .header >>> .Menu .van-grid-item__content div:first-child {
    flex-shrink: 0;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .header >>> .Menu .van-grid-item__content div:last-child {
    text-align: center;
  }

  .content_footer >>> .van-cell::after {
    display: none;
  }

  .tool::before {
    content: "";
    display: block;
  }

  .tool {
    border-radius: 10px;
    background-color: #191C23;
    color:#fff;
    overflow: hidden;
    z-index: 199;
    padding: 0 5px;
    line-height: 22px;
    margin-bottom: 10px;
  }

  .zh-CN >>> .van-grid-item__icon-wrapper,
  .zh-HK >>> .van-grid-item__icon-wrapper {
    word-spacing: 5px;
  }

  .MemberList >>> .van-tabs__wrap {
    height: 38px;
  }

  .MemberList >>> .van-tabs__nav--card {
    margin: 0;
  }

  .MemberList >>> .swipe-item_box {
    display: flex;
    align-items: center;
    padding: 10px 10px !important;
  }

  .swipe-item_box >>> .swipe-item_info_title {
    margin-left: 15px;
    margin-top: 6px;
    height: 18px;
    font-size: 13px !important;
    font-family: Alibaba;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .swipe-item_box >>> .swipe-item_info_title span {
    color: #ff93a4;
  }

  .swipe-item_box >>> .swipe-item_info {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 10px;
    overflow: hidden;
  }

  .swipe-item_box >>> .swipe-item_info_details {
    display: flex;
    margin-top: 4px;
    align-items: flex-start;
    border-bottom: 1px solid #d0d0d0;
  }

  .swipe-item_box >>> .swipe-item_info_details .item_state {
    display: flex;
    align-items: center;
    color: #666;
    font-size: 12px;
    transform: scale(0.8);
    white-space: nowrap;
  }
  .swipe-item_box >>> .swipe-item_info_details .item_state > p {
    width: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .swipe-item_box >>> .swipe-item_info_details .isState {
    color: #4e51bf !important;
  }

  .swipe-item_box >>> .swipe-item_info_details .isState span {
    background-color: #ff93a4 !important;
  }

  .swipe-item_box >>> .swipe-item_info_details .item_state span {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #a0a0a0;
    margin-right: 3px;
    flex-shrink: 0;
    margin-top: 2px;
  }

  .swipe-item_box >>> .swipe-item_info_details .item_site {
    color: #666;
    font-size: 12px;
    transform: scale(0.8);
    white-space: nowrap;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .swipe-item_box >>> .swipe-item_info_details .profit {
    display: flex;
    margin-left: auto;
    align-items: center;
    color: #ff93a4;
  }

  .swipe-item_box >>> .swipe-item_info_details .profit img {
    margin-right: 2px;
  }

  .swipe-item_box >>> .swipe-item_info_details .item_massage {
    margin-left: auto;
    width: 80px;
    padding: 3px 0;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2);
    font-size: 13px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  /* ------------- */
  .MemberList >>> .swipe-item_img_box {
    background: linear-gradient(0deg, #4e51bf, #ff93a4);
    padding: 2px;
    border-radius: 50%;
    overflow: hidden;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2px;
  }

  .MemberList >>> .swipe-item_img_box1 {
    background: gray;
    padding: 2px;
    border-radius: 50%;
    overflow: hidden;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2px;
  }

  .MemberList >>> .swipe-item_img {
    width: 100%;
    height: 100%;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    overflow: hidden;
    background-color: #ffe9ce;
  }

  .MemberList >>> .swipe-item_img img {
    width: 100%;
    height: auto;
    max-height: 100%;
  }

  .MemberList >>> .van-cell__title {
    display: flex;
  }

  .MemberList >>> .van-tab__text {
    /*font-size: 18px;*/
    font-weight: bold;
    display: flex;
    align-items: center;
  }

  .MemberList .van-swipe-item {
    padding: 1px 0;
  }

  .topItem {
    line-height: 1;
    /*background-color: #151d31;*/
    color:#fff;
  }

  .topItem .van-cell__left-icon {
    height: 46px;
    width: 46px;
    /*background-color: #fff;*/
    border-radius: 100%;
    overflow: hidden;
    padding: 2px;
  }

  .TaskHall_info >>> .van-cell__left-icon {
    font-size: 14px;
  }

  .topItem .van-icon__image {
    width: 100%;
    height: 100%;
  }

  .topItem .van-cell__label {
    line-height: 1;
    margin-top: 0;
    color:#fff;
    font-size: 14px;
  }

  .topItem .profit {
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 100px;
    display: flex;
    align-items: center;
    padding: 3px 8px;
    font-size: 13px;
    color: #0e1526;
  }
  .coinitem {
    margin-left: 10px;
    border-radius: 10px;
    background-color: #191C23;
    padding-left: 5px;
    color:#fff;
    margin-right: 10px;
    height: 60px;
    margin-top: 5px;
  }
  .topItem .profit img {
    margin-right: 5px;
  }
  .MyHeader {
    font-size: 19px;
    border-radius: 50%;
    background: pink;
    overflow: hidden;
    width: 72px;
    height: 72px;
  }
  .MyHeader_box {
    padding: 2px;
    overflow: hidden;
    margin-left: 10px;
    flex-shrink: 0;
  }
  .Menu {
    flex-wrap: nowrap;
  }

  .Menu >>> .van-grid-item__content {
    /*background-color: #151d31;*/
    border-radius: 5px;
    color:#cccccc;
    background: transparent none repeat scroll 0% 0%;
  }

  .Menu >>> .van-grid-item__icon-wrapper {
    margin-right: 15px;
    overflow: hidden;
    flex: 1;
  }

  .Title {
    /*background-color: #151d31;*/
    /*color: #fff;*/
    margin: 4px 0;
    padding: 7px 25px;
  }

  .popupBg {
    background: #fff;
    border-radius: 0.8rem;
  }

  .NoticePopup {
    background-size: contain;
    background-color: #fff;
    height: 450px;
  }

  .NoticePopup dd {
    font-size: 13.5px;
    overflow: inherit;
    color: #666666;
  }

  .popup_title {
    display: flex;
    line-height: normal;
    height: 170px;
    justify-content: flex-end;
    box-sizing: border-box;
    padding: 40px 0px 0 0;
  }

  .popup_title div {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 26px;
  }

  .popup_title div span:first-child {
    font-weight: bold;
    color: #4e51bf;
  }

  .popup_title div span:last-child {
    color: #9b9efc;
    letter-spacing: 0.34rem;
    font-size: 22px;
  }

  .popup_title >>> .letter_s {
    letter-spacing: 0.2rem;
    font-size: 25px !important;
  }

  .close img {
    width: 36px;
    margin-top: 20px;
  }

  .van-popup {
    overflow: inherit;
    /* height: 300px; */
  }

  .content_footer_justify >>> .van-cell__left-icon {
    margin-right: 0;
  }

  .content_footer_justify >>> .van-cell__left-icon img {
    width: 1.1em;
    height: 1.1em;
  }

  .content_footer_justify_itemBgc {
    background: linear-gradient(180deg, #4e51bf, #ff93a4);
    padding: 3px;
    border-radius: 16px;
    margin-top: 15px;
  }

  .van-grid-item {
    padding: 0 !important;
  }

  .Site >>> .van-nav-bar__text {
    color: #fff;
    font-size: 15px;
    position: relative;
  }

  .van-nav-bar__text1 >>> .van-nav-bar__text {
    letter-spacing: 0.09rem;
  }

  .Site >>> .van-nav-bar__text::after {
    content: "";
    position: absolute;
    background: url(../../public/static/images/language_after.png) no-repeat;
    background-size: 100% 100%;
    width: 9px;
    height: 5px;
    top: 9px;
    right: -14px;
  }

  .Site >>> .van-nav-bar__right {
    padding: 0;
    padding-right: 35px;
  }

  .TaskHall_info >>> .van-cell__left-icon img {
    width: 11px;
    height: 11px;
  }

  .two img {
    width: 30px;
    height: 20px !important;
  }
</style>
